import React, { useRef, useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Col, Row } from "../layout/Grid";
import * as styles from "./ServicesContainer.module.scss";
import Wordpress from "../../images/service-wordpress.svg";
import Drupal from "../../images/service-drupal.svg";
import Joomla from "../../images/service-joomla.svg";
import Woocommerce from "../../images/service-woocommerce.svg";
import Magenta from "../../images/service-magenta.svg";
import Shopify from "../../images/service-shopify.svg";
import Mongodb from "../../images/service-mongodb.svg";
import Gatsby from "../../images/service-gatsby.svg";
import Laravel from "../../images/service-laravel.svg";
import Express from "../../images/service-express.svg";
import Reacticon from "../../images/service-react.svg";
import Nodejs from "../../images/service-nodejs.svg";
import Nextjs from "../../images/service-nextjs.svg";
import Aws from "../../images/service-aws.svg";
import Php from "../../images/service-php.svg";
import ReactNative from "../../images/service-react-native.svg";
import Swift from "../../images/service-swift.svg";
import Java from "../../images/service-java.svg";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  return windowSize;
};

gsap.registerPlugin(ScrollTrigger);

const Services = [
  {
    name: "Websites",
    id: "webdesign",
    title: (
      <>
        <span className="h2">Websites</span>{" "}
        <span className="h3">that speak for your business</span>
      </>
    ),
    desc: "We team up with marketing departments of large companies and start-ups, working on landing pages, campaign microsites or corporate websites to deliver a delightful user experience for your customer.",
    solutions: [
      "Custom design",
      "WordPress theme development",
      "WordPress plugin development",
    ],
    tools: [
      { src: Wordpress, name: "Wordpress" },
      { src: Drupal, name: "Drupal" },
      { src: Joomla, name: "Joomla" },
    ],
    image: (
      <StaticImage
        src="../../images/website.jpg"
        alt="Websites that speak for your business"
      />
    ),
  },
  {
    name: "Ecommerce",
    id: "ecommerce",
    title: (
      <>
        <span className="h2">Ecommerce platforms</span>{" "}
        <span className="h3">that sell your products</span>
      </>
    ),
    desc: "Ecommerce is the latest business model that everyone is jumping on now. And why not? Your strategy to broaden your brand, customer relations, and online marketing opportunities starts here.",
    solutions: [
      "Payment gateway integration",
      "Billing system integration",
      "Shipping system integration",
    ],
    tools: [
      { src: Woocommerce, name: "Woocommerce" },
      { src: Magenta, name: "Magenta" },
      { src: Shopify, name: "Shopify" },
    ],
    image: (
      <StaticImage
        src="../../images/ecommerce.jpg"
        alt="Ecommerce platforms that sell your products"
      />
    ),
  },
  {
    name: "Software",
    id: "software",
    title: (
      <>
        <span className="h2">Enterprise Software</span>{" "}
        <span className="h3">that increases your productivity</span>
      </>
    ),
    desc: "We create enterprise software that emphasises user-friendliness and performance, focusing on the people. We ensure that the back-end communicates well with the front end.",
    solutions: [
      "Gated content",
      "Authentication and authorisation",
      "Integration with external software/API",
      "Cloud solutions",
    ],
    tools: [
      { src: Mongodb, name: "Mongodb" },
      { src: Gatsby, name: "Gatsby" },
      { src: Laravel, name: "Laravel" },
      { src: Express, name: "Express" },
      { src: Reacticon, name: "React" },
      { src: Nodejs, name: "Nodejs" },
      { src: Aws, name: "AWS" },
      { src: Php, name: "PHP" },
      { src: Nextjs, name: "Nextjs" },
    ],
    image: (
      <StaticImage
        src="../../images/software.jpg"
        alt="Enterprise Software that increases your productivity"
      />
    ),
  },
  {
    name: "Apps",
    id: "apps",
    title: (
      <>
        <span className="h2">Mobile Apps</span>{" "}
        <span className="h3">that bring value to customers</span>
      </>
    ),
    desc: "We design, build and ship mobile apps from prototypes to full-scale platforms, promoting exceptional user experiences. We ensure elegant apps design and smoothly functional for your business and clients.",
    solutions: [
      "iOS app development",
      "Android app development",
      "Publish to App Store and Play Store",
    ],
    tools: [
      { src: ReactNative, name: "React Native" },
      { src: Swift, name: "Swift" },
      // { src: Firebase, name: "Firebase" },
      { src: Java, name: "Java" },
    ],
    image: (
      <StaticImage
        src="../../images/app.jpg"
        alt="Mobile Apps that bring value to customers"
      />
    ),
  },
];

const ServicesContainer = () => {
  const ref = useRef(null);
  // const [isDesktop, setDesktop] = useState(
  //   typeof window !== "undefined" && window.innerWidth > 768
  // );
  // const updateMedia = () => {
  //   setDesktop(typeof window !== "undefined" && window.innerWidth > 768);
  // };
  const size = useWindowSize();
  useEffect(() => {
    let contenttl = [];
    let titletl = [];
    let imagetl = [];
    let exittl = [];
    const serviceElement = ref.current;
    const serviceImages = gsap.utils.toArray(
      serviceElement.querySelectorAll(".image")
    );
    const serviceContents = gsap.utils.toArray(
      serviceElement.querySelectorAll(".content")
    );
    const serviceTitles = gsap.utils.toArray(
      serviceElement.querySelectorAll(".title")
    );
    const serviceWrapper = gsap.utils.toArray(
      serviceElement.querySelector(".service-wrapper")
    );
    if (typeof window !== "undefined" && size.width >= 769) {
      serviceContents.forEach((content, i) => {
        let serviceImage = serviceImages[i];
        let serviceTitle = serviceTitles[i];
        const tl = gsap
          .timeline({
            scrollTrigger: {
              trigger: content,
              start: "top 60%",
              toggleActions: "play reverse play reverse",
            },
          })
          .from(content, {
            opacity: 0,
            y: 100,
          });
        contenttl.push(tl.scrollTrigger);

        const tl2 = gsap
          .timeline({
            scrollTrigger: {
              trigger: content,
              start: "top 70%",
              end: "bottom 70%",
              toggleActions: "play reverse play reverse",
            },
          })
          .from(serviceTitle, {
            opacity: 0,
          });
        titletl.push(tl2.scrollTrigger);

        const tl3 = gsap
          .timeline({
            scrollTrigger: {
              trigger: content,
              start: "top 70%",
              end: "bottom 70%",
              // markers: true,
              toggleActions: "play reverse play reverse",
            },
          })
          .from(serviceImage, {
            autoAlpha: 0,
            x: -100,
          });
        imagetl.push(tl3.scrollTrigger);

        const tl4 = gsap
          .timeline({
            scrollTrigger: {
              trigger: serviceElement,
              start: "bottom-=8% 70%",
              end: "bottom top",
              // markers: true,
              toggleActions: "play reverse play reverse",
            },
          })
          .to(serviceWrapper, {
            autoAlpha: 0,
            y: -40,
          });
        exittl.push(tl4.scrollTrigger);
      });
      return () => {
        contenttl.forEach((tl) => tl.kill());
        titletl.forEach((tl2) => tl2.kill());
        imagetl.forEach((tl3) => tl3.kill());
        // return () => {
        //   window.removeEventListener("resize", updateMedia);
        // };
      };
      // } else if (typeof window !== "undefined" && size.width <= 768) {
      //   return () => {
      //     window.removeEventListener("resize", updateMedia);
      //   };
    }
  }, [size.width]);
  return (
    <>
      <section ref={ref} className={`section-light ${styles.desktopContainer}`}>
        <div className="section wrapper">
          <Row className="service-wrapper">
            <Col width="40%">
              {Services.map((item, index) => {
                return (
                  <div key={index} className={`image ${styles["image"]}`}>
                    {item.image}
                  </div>
                );
              })}
            </Col>
            <Col width="55%" className={styles.contentWrapper}>
              {Services.map((item, idx) => {
                return (
                  <div key={idx} id={item.id}>
                    <div className="title">{item.name}</div>
                    <div className={`content ${styles["content"]}`}>
                      <h2 className={styles["title"]}>{item.title}</h2>
                      <p>{item.desc}</p>
                      <dl className={styles["list"]}>
                        <dt className="h6">Tools</dt>
                        <dd>
                          <ul className={styles["tools"]}>
                            {item.tools.map((tool, i) => {
                              return (
                                <li key={i}>
                                  <img src={tool.src} alt={tool.name}></img>
                                </li>
                              );
                            })}
                          </ul>
                        </dd>
                        <dt className="h6">Solutions</dt>
                        <dd>
                          <ul className="custom-list">
                            {item.solutions.map((solution, i) => {
                              return <li key={i}>{solution}</li>;
                            })}
                          </ul>
                        </dd>
                      </dl>
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </div>
      </section>
      <section className={`section-light ${styles.mobileContainer}`}>
        <div className="section wrapper">
          <Row>
            {Services.map((item, index) => {
              return (
                <Col className={styles.serviceList} key={index} id={item.id}>
                  <div className="title">{item.name}</div>
                  <div className={`image ${styles["image"]}`}>{item.image}</div>
                  <div className={`content ${styles["content"]}`}>
                    <h2 className={styles["title"]}>{item.title}</h2>
                    <p>{item.desc}</p>
                    <dl className={styles["list"]}>
                      <div>
                        <dt className="h6">Tools</dt>
                        <dd>
                          <ul className={styles["tools"]}>
                            {item.tools.map((item, i) => {
                              return (
                                <li key={i}>
                                  <img src={item.src} alt={item.name}></img>
                                </li>
                              );
                            })}
                          </ul>
                        </dd>
                      </div>
                      <div>
                        <dt className="h6">Solutions</dt>
                        <dd>
                          <ul className="custom-list">
                            {item.solutions.map((item, index) => {
                              return <li key={index}>{item}</li>;
                            })}
                          </ul>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </section>
    </>
  );
};

export default ServicesContainer;
