// extracted by mini-css-extract-plugin
export var content = "ServicesContainer-module--content--5a67c";
export var contentWrapper = "ServicesContainer-module--content-wrapper--a5d8a";
export var desktopContainer = "ServicesContainer-module--desktop-container--11714";
export var image = "ServicesContainer-module--image--c8bc3";
export var imageWrap = "ServicesContainer-module--image-wrap--f5016";
export var list = "ServicesContainer-module--list--353d7";
export var mobileContainer = "ServicesContainer-module--mobile-container--b4d00";
export var serviceList = "ServicesContainer-module--service-list--6233a";
export var title = "ServicesContainer-module--title--edfcc";
export var tools = "ServicesContainer-module--tools--a588b";